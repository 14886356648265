import React from 'react'
import FormRow from '../Forms/FormRow'
import Radio from '../Forms/Radio'
import { Button, Row, Col } from 'reactstrap'
import Validator from 'validator'
import { fieldDefinitions } from './fieldDefinitions'

const isNotEmpty = s => !Validator.isEmpty(s)

export default class PersonalPane extends React.Component {
  render() {
    return (
      <div hidden={this.props.hidden}>
        <h4>Details of your talk submission</h4>
        <Radio
          fieldname="talktype"
          {...fieldDefinitions.talktype}
          value={this.props.submission.talktype}
          onChange={this.props.handleChange}
          choices={['Talk', 'Workshop']}
        />
        <FormRow
          fieldname="presentation_title"
          value={this.props.submission.presentation_title}
          {...fieldDefinitions.presentation_title}
          onChange={this.props.handleChange}
          validators={[
            isNotEmpty,
            s => Validator.isLength(s, { min: 3, max: 250 }),
          ]}
        />
        <FormRow
          type="textarea"
          rows={10}
          fieldname="presentation_summary"
          value={this.props.submission.presentation_summary}
          {...fieldDefinitions.presentation_summary}
          onChange={this.props.handleChange}
          validators={[
            isNotEmpty,
            s => Validator.isLength(s, { min: 100, max: 4000 }),
          ]}
        />
        <FormRow
          type="textarea"
          rows={5}
          fieldname="presentation_notes"
          value={this.props.submission.presentation_notes}
          {...fieldDefinitions.presentation_notes}
          onChange={this.props.handleChange}
        />
        <Row>
          <Col>
            <Button
              color="-primary"
              block
              size="lg"
              onClick={this.props.backward}
            >
              &laquo; back to your personal data
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              color="-primary"
              block
              size="lg"
              onClick={this.props.forward}
            >
              proceed to submission review &raquo;
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}
