import React from 'react'

import { Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import Wildcard from '../Wildcard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrinHearts } from '@fortawesome/free-solid-svg-icons'

export default class ThankYou extends React.Component {
  render() {
    return (
      <Wildcard
        align="center"
        color="tertiary"
        title={`Thank you, ${this.props.submission.name || ''}`}
        content={
          <Row>
            <Col xs={2} className="d-none d-lg-flex">
              <FontAwesomeIcon icon={faGrinHearts} size="6x" />
            </Col>
            <Col>
              <p>
                Your proposal &quot;
                <strong>
                  {this.props.lastSubmission
                    ? this.props.lastSubmission.presentation_title
                    : ''}
                </strong>
                &quot; has been successfully submitted. Thank you so much for
                your time and effort! We're receiving and processing all
                submissions and will come
                back to you with our final decision. If you have any (any!)
                questions about Dev Day and what to prepare for your talk,
                don't hesitate to contact{' '}
                <Link to="/team">any of our team members</Link> or drop us a line
                on{' '}
                <a href="mailto:devday@turbinekreuzberg.com">
                  devday@turbinekreuzberg.com
                </a>
              </p>
              <p>
                After receiving your submission we saved it along with your
                personal data in <strong>your browser's local storage</strong>.
                So in case you want to submit yet another talk, lets go:
              </p>
            </Col>
          </Row>
        }
        cta={
          <Row>
            <Col>
              <h6>Want to submit another one?</h6>
              <Link
                className={`btn btn-block btn--primary`}
                to="/call-for-speakers"
                onClick={this.props.dismiss}
              >
                call for speakers
              </Link>
            </Col>
          </Row>
        }
      />
    )
  }
}
