import React from 'react'

import Layout from '../components/common/Layout'
import Section from '../components/common/Section'
import Countdown from '../components/common/Countdown'
import PersonalPane from '../components/common/Cfp/PersonalPane'
import TalkPane from '../components/common/Cfp/TalkPane'
import ReviewPane from '../components/common/Cfp/ReviewPane'

import ThankYou from '../components/common/Cfp/ThankYou'
import * as fieldDefinitions from '../components/common/Cfp/fieldDefinitions'
import postSubmission from '../components/common/Forms/postSubmission'
import Intro from '../components/common/Cfp/Intro'
import { Zoom, Fade } from 'react-reveal'

import Validator from 'validator'
import { Element, scroller } from 'react-scroll'
import { Row, Col, Form, Button } from 'reactstrap'
import keyvisual from '../components/2020/media/bg6.svg'

const NETLIFY_FORM_NAME = 'dd_cfp2019'
const SCROLL_BASE = 'scroll_base'

export default class CfpPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            submission: fieldDefinitions.empty,
            currentPane: 'personal',
            submittable: false,
            submissions: [],
            submitted: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.navigatePane = this.navigatePane.bind(this)
    }

    componentDidMount() {
        const submission = localStorage.getItem('submission')
        const _submissions = localStorage.getItem('submissions')

        const mountChanges = {
            submissions: null === _submissions ? [] : JSON.parse(_submissions),
        }
        if (null !== submission) {
            mountChanges.submission = {
                ...fieldDefinitions.empty,
                ...JSON.parse(submission),
            }
            mountChanges.submittable = this.submittable(mountChanges.submission)
        }

        this.setState(mountChanges)
    }

    handleChange(change) {
        const submission = { ...this.state.submission, [change.name]: change.value }

        this.setState(
            {
                submission,
                submittable: this.submittable(submission),
            },
            () => {
                localStorage.setItem(
                    'submission',
                    JSON.stringify(this.state.submission)
                )
            }
        )
    }

    handleSubmit(event) {
        event.preventDefault()

        const submission = this.state.submission
        if (!this.submittable(submission)) {
            alert('not ready yet')
            return false
        }

        postSubmission(NETLIFY_FORM_NAME, submission)
            .then(() => {
                this.storeSubmission(submission)
                this.setState({ submitted: true })
                this.scrollTop()
            })
            .catch(error => alert(error))

        return false
    }

    storeSubmission(submission) {
        const historicSubmission = {
            ...submission,
            presentation_title: '',
            presentation_summary: '',
            presentation_notes: '',
        }
        localStorage.setItem('submission', JSON.stringify(historicSubmission))

        let submissions = localStorage.getItem('submissions')
        if (null === submissions) {
            submissions = []
        } else {
            submissions = JSON.parse(submissions)
        }
        submissions.push({
            date: new Date(),
            presentation_title: submission.presentation_title,
            presentation_summary: submission.presentation_summary,
            presentation_notes: submission.presentation_notes,
        })
        localStorage.setItem('submissions', JSON.stringify(submissions))
        this.setState({
            submission: historicSubmission,
            submissions,
        })
    }

    navigatePane(pane) {
        this.setState({ currentPane: pane }, this.scrollTop)
        return false
    }

    scrollTop() {
        scroller.scrollTo(SCROLL_BASE, {
            duration: 350,
            delay: 50,
            smooth: true,
            offset: -140, // Scrolls to element + 50 pixels down the page
        })
    }

    submittable(submission) {
        return fieldDefinitions.mandatory.reduce((current, def) => {
            let valid = current

            if (def.type == 'email') {
                valid = Validator.isEmail(submission[def.name]) && valid
            } else if (def.type == 'boolean') {
                valid = submission[def.name] === true && valid
            } else {
                valid = !Validator.isEmpty(submission[def.name]) && valid
            }
            return valid
        }, true)
    }

    render() {
        return (
            <Layout >
                <Section xlSize={{ size: 12 }} section="section section--fit" bg={keyvisual}>

                    <div className="page-header ">
                        <h1 className="display-one">Give a talk <span className="outline">at DEVDAY</span></h1>
                    </div>

                    {!this.state.submitted && (
                        <Fade left distance="20px">
                            <Intro />
                        </Fade>
                    )}

                    <Element name={SCROLL_BASE} />
                    {this.state.submitted ? (
                        <ThankYou
                            submission={this.state.submission}
                            lastSubmission={this.state.submissions.slice(-1)[0]}
                            dismiss={() => {
                                this.setState({
                                    currentPane: 'personal',
                                    submitted: false,
                                    submittable: false,
                                })
                            }}
                        />
                    ) : (
                            <Form
                                name={NETLIFY_FORM_NAME}
                                className="mt-5 p-3"
                                style={{ backgroundColor: 'white' }}
                                method="post"
                                onSubmit={this.handleSubmit}
                                data-netlify="true"
                                data-netlify-honeypot="firstname"
                            >
                                <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
                                <p hidden>
                                    <label>
                                        Please provide your first name: <input name="firstname" />
                                    </label>
                                </p>

                                <PersonalPane
                                    hidden={this.state.currentPane !== 'personal'}
                                    submission={this.state.submission}
                                    handleChange={this.handleChange}
                                    forward={this.navigatePane.bind(this, 'talk')}
                                />

                                <TalkPane
                                    hidden={this.state.currentPane !== 'talk'}
                                    submission={this.state.submission}
                                    handleChange={this.handleChange}
                                    backward={this.navigatePane.bind(this, 'personal')}
                                    forward={this.navigatePane.bind(this, 'review')}
                                />

                                <ReviewPane
                                    hidden={this.state.currentPane !== 'review'}
                                    submission={this.state.submission}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleSubmit}
                                    backward={this.navigatePane.bind(this, 'talk')}
                                    submitButton={
                                        <Button
                                            color={this.state.submittable ? '-secondary' : 'tertiary'}
                                            size="lg"
                                            block
                                            disabled={!this.state.submittable}
                                        >
                                            submit
                  </Button>
                                    }
                                />
                            </Form>
                        )}

                    <div
                        hidden={this.state.submitted || this.state.submissions.length > 0}
                        className="margin-top"
                    >
                        <p>
                            We're saving your personal data in your browser's <strong>local storage</strong> instead
                            asking you to login. To submit several talks just come
                            back here - you won't have to provide all the data twice.
                        </p>
                    </div>
                </Section>


                {
                    this.state.submissions.length > 0 && (
                        <Section section="section">
                            <h4>You already submitted these talks</h4>
                            {this.state.submissions.map(subm => {
                                const date = new Date(subm.date)
                                return (
                                    <div key={`subm-${subm.date}`}>
                                        <h6>{subm.presentation_title}</h6>
                                        <p className="sub-head">
                                            {date.toLocaleDateString()} {date.toLocaleTimeString()}
                                        </p>
                                        <p className="indent">{subm.presentation_summary}</p>
                                    </div>
                                )
                            })}
                        </Section>
                    )
                }
            </Layout >
        )
    }
}

//export const query = graphql``
