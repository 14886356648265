import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import ServiceImage from '../ServiceImage'
import { Fade } from 'react-reveal'

export default props => {

    const data = useStaticQuery(graphql`
    {
        markdownRemark(frontmatter: { slug: { eq: "cfs-intro" } }) {
            html
        }
    }
   `)

    return <ServiceImage
        wide
        align="left"
        color="primary"
        section="section"
        contentClass=""
        image="https://res.cloudinary.com/turbinekreuzberg/image/upload/t_maxeco/devday19/faro/140-P1020760.jpg"
        cta={
            <Link
                to="/inspire-me"
                style={{ fontSize: '1.2em' }}
                className=" dashed  dashed--hover in-view__child in-view__child--in"
            >
                see rules, hard facts &amp; get inspiration
      </Link>
        }
    >
        <Fade right distance="20px">
            <div className="" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </Fade>
    </ServiceImage>
}

