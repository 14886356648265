import _map from 'lodash.map'
import _mapval from 'lodash.mapvalues'

const fieldDefinitions = {
  name: {
    label: 'Your name',
    help: 'How should we call you?',
    feedback: 'You have to provide a name.',
    mandatory: true,
  },
  email: {
    label: 'Your email address',
    mandatory: true,
    type: 'email',
    help: 'We need your mail address to get in touch with you',
    feedback: 'You have to provide a valid email address.',
  },
  github: {
    label: 'Your github handle',
    placeholder: '@github',
    help:
      'If you provide your github handle we can announce it along with your talk',
  },
  twitter: {
    label: 'Your twitter handle',
    placeholder: '@your_handle',
    help:
      'If you provide your twitter handle we mention you in our announcements so that people can get in touch with you',
  },
  linkedin: {
    label: 'Your LinkedIn profile',
    placeholder: 'https://www.linkedin.com/in/williamhgates/',
    help: "It's a business standard and nice for people to get in touch",
  },
  country: {
    label: 'Your country of origin',
    placeholder: 'Neverland',
    help:
      "Please provide the country that you're travelling from / where you currently are located",
  },
  company: {
    label: 'Your company',
    placeholder: 'Acme Inc',
    help:
      "If you work for a company or the company your talk's content is related to",
  },

  biography: {
    label: 'Your biography',
    mandatory: true,
    feedback: 'please provide a biography',
    help:
      "let your audience get an idea of who you are and what's your experience",
  },
  talktype: {
    label: 'Submission type',
    mandatory: true,
    help: 'Do you want to do a workshop or a talk?',
  },
  presentation_title: {
    label: "Your submission's title",
    mandatory: true,
    help: 'Please keep it short and concise',
    placeholder: `How ACME made my coding life simpler.`,
    feedback: 'You have to provide a title for your submission',
  },
  presentation_summary: {
    label: "Your submission's summary",
    mandatory: true,
    placeholder:
      'Learning JS basics can be hard. This talk demonstrates how to crosscompile Rust to ES8 and deploy it with self provisioning docker containers on a virtual k8s cluster on any cloud',
    help:
      'a good summary gets to the point fast, contains short sentences and makes clear what others can learn when hearing your talk / attending your workshop',
    feedback: 'You have to provide an abstract for your submission (min. 100 chars)',
  },
  presentation_notes: {
    label: 'notes / questions / hints / examples / previous talks',
    placeholder:
      'See my slides here: https://www.example.com/amazing-slides.pdf',
    help:
      'if you want, leave any notes for this submission here, e.g. links to slides or youtube videos',
  },
  gdpr: {
    label: 'You accept our privacy policy',
    mandatory: true,
    type: 'boolean',
  },
  needTravelExpenses: {
    label: 'I need support with travel and/or accommodation expenses',
    help:
      "please check this box if you'd like to give a talk at DEVDAY'20 but cannot afford to come and stay on your own.",
    type: 'boolean',
  },
}

const mandatory = _map(fieldDefinitions, (def, key) =>
  def.mandatory ? { ...def, name: key } : false
).filter(d => d !== false)

const empty = _mapval(fieldDefinitions, def => {
  if (def.type === 'boolean') return null
  else return ''
})

export { fieldDefinitions, mandatory, empty }
