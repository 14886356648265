import React from 'react'

import { Row, Col } from 'reactstrap'
import { DateTime, Duration } from 'luxon'
import countdownStyles from './scss/countdown.module.scss'

const CountdownElement = props => (
  <Col xs={3} className={countdownStyles.el}>
    <div className={countdownStyles.count}>{props.value}</div>
    <div className={countdownStyles.label}>{props.label}</div>
  </Col>
)

export default class Countdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targetDate: DateTime.fromSQL(props.date),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  countdown() {
    const diff = this.state.targetDate.diffNow(['days', 'hours', 'minutes', 'seconds'])
    if (diff.as('seconds') < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
    } else {
      this.setState({ ...diff.values })
    }
  }

  componentDidMount() {
    this.countdown()
    const intervalId = setInterval(this.countdown.bind(this), 1000)
    this.setState({ intervalId })
  }

  render() {
    return (
      <Row className={countdownStyles.countdown}>
        <CountdownElement label="Days" value={this.state.days} />
        <CountdownElement label="Hours" value={this.state.hours} />
        <CountdownElement label="Minutes" value={this.state.minutes} />
        <CountdownElement label="Seconds" value={Math.round(this.state.seconds)} />
      </Row>
    )
  }
}
