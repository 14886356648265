import React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'

import { FormGroup, Input, FormText, FormFeedback } from 'reactstrap'

export default class FormRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      valid: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  validate(value) {
    return this.props.validators.reduce((current, validator) => {
      return validator(value) && current
    }, true)
  }
  handleInputChange(event) {
    event.preventDefault()
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const isValid = this.validate(value)

    this.props.onChange({
      name: this.props.fieldname,
      value,
      valid: isValid,
    })

    this.setState({ valid: isValid })
  }

  render() {
    const invalid = this.state.valid === null ? false : !this.state.valid
    return (
      <FormGroup>
        <Label {...this.props} invalid={invalid} />
        <Input
          {...this.props}
          type={this.props.type || 'text'}
          name={this.props.fieldname}
          value={this.props.value}
          onChange={this.handleInputChange}
          onFocus={() => this.setState({ focus: true })}
          onBlur={evt => {
            this.handleInputChange(evt)
            this.setState({ focus: false })
          }}
          aria-required={this.props.mandatory}
          aria-invalid={!this.state.valid}
          placeholder={this.props.placeholder || this.props.label}
          invalid={invalid}
        />
        {this.state.valid === false && this.props.feedback && (
          <FormFeedback>{this.props.feedback}</FormFeedback>
        )}
        {this.props.help && this.state.focus && (
          <FormText>{this.props.help}</FormText>
        )}
      </FormGroup>
    )
  }
}

FormRow.propTypes = {
  label: PropTypes.string,
  fieldname: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func),
  placeholder: PropTypes.string,
  help: PropTypes.string,
  feedback: PropTypes.string,
  mandatory: PropTypes.bool,
}

FormRow.defaultProps = {
  validators: [],
  mandatory: false,
}
