import React from 'react'
import FormRow from '../Forms/FormRow'
import Radio from '../Forms/Radio'
import Validator from 'validator'
import { Button, Row, Col } from 'reactstrap'
import { fieldDefinitions } from './fieldDefinitions'

const isNotEmpty = s => !Validator.isEmpty(s)

export default class PersonalPane extends React.Component {
  render() {
    return (
      <div hidden={this.props.hidden}>
        <h4>Your personal data</h4>
        <FormRow
          fieldname="name"
          value={this.props.submission.name}
          {...fieldDefinitions.name}
          onChange={this.props.handleChange}
          validators={[isNotEmpty]}
          autoComplete="name"
        />
        <FormRow
          fieldname="email"
          value={this.props.submission.email}
          {...fieldDefinitions.email}
          onChange={this.props.handleChange}
          validators={[Validator.isEmail]}
          autoComplete="email"
        />

        <Row>
          <Col md={4}>
            <FormRow
              fieldname="github"
              value={this.props.submission.github}
              {...fieldDefinitions.github}
              onChange={this.props.handleChange}
            />
          </Col>
          <Col md={4}>
            <FormRow
              fieldname="twitter"
              value={this.props.submission.twitter}
              {...fieldDefinitions.twitter}
              onChange={this.props.handleChange}
            />
          </Col>
          <Col md={4}>
            <FormRow
              fieldname="linkedin"
              value={this.props.submission.linkedin}
              {...fieldDefinitions.linkedin}
              onChange={this.props.handleChange}
            />
          </Col>
        </Row>
        <FormRow
          fieldname="country"
          value={this.props.submission.country}
          {...fieldDefinitions.country}
          autoComplete="country-name"
          onChange={this.props.handleChange}
        />
        <FormRow
          fieldname="company"
          value={this.props.submission.company}
          {...fieldDefinitions.company}
          onChange={this.props.handleChange}
        />
        <FormRow
          type="textarea"
          rows={5}
          fieldname="biography"
          value={this.props.submission.biography}
          {...fieldDefinitions.biography}
          onChange={this.props.handleChange}
          validators={[isNotEmpty]}
          placeholder={`${
            this.props.submission.name
              ? this.props.submission.name + ' has been '
              : `I've been`
            } giving amazing talks for 10 years. After founding Acme Inc. in 1999 I went on a boat trip in South Canada...`}
        />

        <Button color="-primary" size="lg" block onClick={this.props.forward}>
          proceed to your contribution details &raquo;
        </Button>
      </div>
    )
  }
}
