import React from 'react'
import PropTypes from 'prop-types'
import Label from './Label'
import { ButtonGroup, Button, FormGroup, FormText } from 'reactstrap'

export default class Radio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(choice) {
    this.props.onChange({
      name: this.props.fieldname,
      value: choice,
      valid: true,
    })
  }

  render() {
    const buttons = this.props.choices.map(ch => {
      const active = this.props.value === ch
      return (
        <Button
          color=""
          onClick={() => this.handleInputChange(ch)}
          active={active}
          style={{ cursor: 'pointer' }}
          className={`no-shadow ${active ? 'btn--primary' : ''}`}
          key={`btn-${ch}`}
        >
          {ch}
        </Button>
      )
    })
    return (
      <FormGroup>
        <Label {...this.props} invalid={null == this.props.value} />
        <div>
          <ButtonGroup>{buttons}</ButtonGroup>
        </div>
        <FormText>{this.props.help}</FormText>
      </FormGroup>
    )
  }
}
