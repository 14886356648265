import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'

export default props => (
  <Label
    for={props.fieldname}
    className={
      (props.invalid ? 'text-danger ' : '') +
      (props.mandatory ? 'font-weight-bold ' : '')
    }
  >
    {props.label}
    {props.mandatory ? ' *' : ''}
  </Label>
)
