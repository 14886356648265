import React from 'react'

import Checkbox from '../Forms/Checkbox'
import { Button, Row, Col } from 'reactstrap'

import SubmissionPreview from './SubmissionPreview'
import { fieldDefinitions } from './fieldDefinitions'

export default class ReviewPane extends React.Component {
  render() {
    return (
      <div hidden={this.props.hidden}>
        <h3>Review and submit</h3>
        <SubmissionPreview submission={this.props.submission} />

        <Checkbox
          fieldname="gdpr"
          value={this.props.submission.gdpr}
          {...fieldDefinitions.gdpr}
          label={
            <span>
              I've read and accepted the{' '}
              <a href="/privacy-policy" target="_blank">
                privacy policy
              </a>
            </span>
          }
          onChange={this.props.handleChange}
        />

        <Checkbox
          fieldname="needTravelExpenses"
          value={this.props.submission.needTravelExpenses}
          {...fieldDefinitions.needTravelExpenses}
          onChange={this.props.handleChange}
        />

        <Row>
          <Col>
            <Button
              color="-primary"
              size="lg"
              block
              onClick={this.props.backward}
            >
              &laquo; back to submission details
            </Button>
          </Col>
          <Col>{this.props.submitButton}</Col>
        </Row>
      </div>
    )
  }
}
