import React from 'react'
import Label from './Label'
import { FormGroup, CustomInput, FormText } from 'reactstrap'

export default class FormRow extends React.Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    this.props.onChange({
      name: this.props.fieldname,
      value: target.checked,
      valid: true,
    })
  }

  render() {
    return (
      <FormGroup>
        <div>
          <CustomInput
            type="checkbox"
            id={this.props.fieldname}
            name={this.props.fieldname}
            onClick={this.handleInputChange}
            aria-required={this.props.mandatory}
            label={<Label {...this.props} />}
            checked={this.props.value}
          />
        </div>
        <FormText>{this.props.help}</FormText>
      </FormGroup>
    )
  }
}
