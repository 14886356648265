import React from 'react'
import map from 'lodash.map'
import { fieldDefinitions } from './fieldDefinitions'

export default class SubmissionPreview extends React.Component {
  render() {
    const definitionList = map(fieldDefinitions, (definition, key) => {
      let val = this.props.submission[key]
      if (typeof val === 'boolean') {
        if (val === true) {
          val = 'yes'
        } else if (val === false) {
          val = 'no'
        } else {
          val = 'unk'
        }
      }
      return (
        <React.Fragment key={`subm-${key}`}>
          <dt
            className={`col-sm-4 ${definition.mandatory ? 'font-italic' : ''}`}
          >
            {definition.label}
            {definition.mandatory ? '*' : ''}
          </dt>
          <dd className="col-sm-8">{val}</dd>
        </React.Fragment>
      )
    })

    return (
      <div>
        <dl className="row">{definitionList}</dl>
      </div>
    )
  }
}
