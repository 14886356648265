import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const SLIDE_INTERVAL = 2500

class CarouselFigure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idx: 0,
      intervalId: 0,
    }
  }
  componentDidMount() {
    const intervalId = setInterval(() => {
      this.setState((state, props) => ({
        idx: (state.idx + 1) % props.items.length,
      }))
    }, SLIDE_INTERVAL)
    this.setState({ intervalId })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }
  render() {
    return (
      <figure
        style={{
          backgroundImage: `url(${this.props.items[this.state.idx]})`,
        }}
      />
    )
  }
}

export default props => (
  <div className={`service-image ${props.wide ? 'service-image--wide' : ''}`}>
    <Row className="align-items-center">
      <Col lg={props.size || 6} className="service-image__thumb">
        {Array.isArray(props.image) ? (
          <CarouselFigure items={props.image} />
        ) : (
            <figure
              title={props.imgTitle || ''}
              style={{
                backgroundImage: `url(${props.image})`,
              }}
            />
          )}
      </Col>

      <Col
        lg={props.size ? 12 - props.size : 6}
        className="service-image__copy"
      >
        {props.subtitle && (
          <span className="d-block sub-head-2">{props.subtitle}</span>
        )}
        {props.title && <h3 className="margin-top-0">{props.title}</h3>}

        <div className={props.contentClass || ''}>{props.children}
          {props.cta && <p className="indent">{props.cta}</p>}
        </div>
      </Col>
    </Row>
  </div>
)
